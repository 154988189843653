import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { CrudOperationType, HttpMethod } from '@safarilaw-webapp/shared/common-objects-models';
// Yes - importing CrudTestError via direct import, not barrel. It's not exposed in a barrel since it's not
// something we use outside of non-prod enivronments and its exclusively used only here and in dev version of test-error-service
import { CrudTestError } from 'libs/shared/crud/src/lib/crud/services/crud-orchestrator/crud-test-error.service.dev';

import { FormsModule } from '@angular/forms';
import { SafariAccordionComponent } from '@safarilaw-webapp/shared/ui-kit/accordion';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { IndividualConfig, ToastrService } from 'ngx-toastr';
import { SafariSmartComponent } from '@safarilaw-webapp/shared/ui-kit/basepage';

@Component({
  selector: 'sl-dev-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.css'],
  imports: [FormsModule, SafariAccordionComponent, NgMultiSelectDropDownModule]
})
export class ErrorComponent extends SafariSmartComponent implements OnInit {
  @Input()
  isHosted = false;
  HttpMethod = HttpMethod;
  buttonClicked = false;

  static _toastrConfig: Partial<IndividualConfig<any>> = {
    positionClass: 'toast-top-center',
    easeTime: 0,
    disableTimeOut: false,
    tapToDismiss: true,
    closeButton: true,
    toastClass: 'toast-primary',
    timeOut: 3000
  };

  static defaultEndpointFailures: CrudTestError[] = [
    {
      endpoint: 'https://.+?(garnishments|documentRequests|litigations|otherMatters)/[0-9]+(/correspondence/)[0-9]+(/attachments$|/attachments/$|/attachments/[^/]+$)',
      method: [
        {
          id: HttpMethod.POST,
          name: 'POST'
        },
        {
          id: HttpMethod.PUT,
          name: 'PUT'
        }
      ],
      failureRate: 0,
      errorCode: 500,
      name: 'Matter Correspondence Attachments',
      canDelete: false
    },
    {
      endpoint: 'https://.+?(garnishments|documentRequests|litigations|otherMatters)/[0-9]+(/notes$|/notes/$|/notes/[^/]+$)',
      method: [
        {
          id: HttpMethod.POST,
          name: 'POST'
        },
        {
          id: HttpMethod.PUT,
          name: 'PUT'
        }
      ],
      failureRate: 0,
      errorCode: 500,
      name: 'Matter Notes',
      canDelete: false
    },
    {
      method: [
        {
          id: HttpMethod.PUT,
          name: 'PUT'
        },
        {
          id: HttpMethod.POST,
          name: 'POST'
        }
      ],
      endpoint: 'https://.+?(users$|users/$|users/[^/]+$)',
      failureRate: 0,
      errorCode: 500,
      name: 'Users',
      canDelete: false
    },
    {
      failureRate: 0,
      errorCode: 500,
      name: 'Matter Participants',
      endpoint: 'https://.+?(garnishments|documentRequests|litigations|otherMatters)/[0-9]+(/participants$|/participants/$|/participants/[^/]+$)',
      method: [
        {
          id: HttpMethod.POST,
          name: 'POST'
        },
        {
          id: HttpMethod.PUT,
          name: 'PUT'
        }
      ],
      canDelete: false
    },
    {
      failureRate: 0,
      errorCode: 500,
      method: [
        {
          id: HttpMethod.POST,
          name: 'POST'
        },
        {
          id: HttpMethod.PUT,
          name: 'PUT'
        }
      ],
      name: 'Matter Attachments',
      canDelete: false,
      endpoint: 'https://.+?(garnishments|documentRequests|litigations|otherMatters)/[0-9]+(/attachments$|/attachments/$|/attachments/[^/]+$)'
    },
    {
      failureRate: 0,
      errorCode: 500,
      method: [
        {
          id: HttpMethod.POST,
          name: 'POST'
        },
        {
          id: HttpMethod.PUT,
          name: 'PUT'
        }
      ],
      name: 'Matter Links',
      endpoint: 'https://.+?(garnishments|documentRequests|litigations|otherMatters)/[0-9]+(/links$|/links/$|/links/[^/]+$)',
      canDelete: false
    },
    {
      failureRate: 0,
      errorCode: 500,
      method: [
        {
          id: HttpMethod.POST,
          name: 'POST'
        },
        {
          id: HttpMethod.PUT,
          name: 'PUT'
        }
      ],
      name: 'Matter Attachments Move',
      endpoint: 'https://.+?(garnishments|documentRequests|litigations|otherMatters)/[0-9]+(/attachments/move$|/attachments/move/$)',
      canDelete: false
    },
    {
      failureRate: 0,
      errorCode: 500,
      method: [
        {
          id: HttpMethod.POST,
          name: 'POST'
        },
        {
          id: HttpMethod.PUT,
          name: 'PUT'
        }
      ],
      name: 'Matter Attachment Folders',
      canDelete: false,
      endpoint: 'https://.+?(garnishments|documentRequests|litigations|otherMatters)/[0-9]+(/attachmentFolders$|/attachmentFolders/$|/attachmentFolders/[^/]+$)'
    },
    {
      failureRate: 0,
      errorCode: 500,
      method: [
        {
          id: HttpMethod.POST,
          name: 'POST'
        },
        {
          id: HttpMethod.PUT,
          name: 'PUT'
        }
      ],
      name: 'Matter Attachment Folder Move',
      canDelete: false,
      endpoint: 'https://.+?(garnishments|documentRequests|litigations|otherMatters)/[0-9]+(/attachmentFolders/move$|/attachmentFolders/move/$)'
    },
    {
      failureRate: 0,
      errorCode: 500,
      method: [
        {
          id: HttpMethod.POST,
          name: 'POST'
        },
        {
          id: HttpMethod.PUT,
          name: 'PUT'
        }
      ],
      name: 'Garnishment Answer Resend',
      canDelete: false,
      endpoint: 'https://.+?(garnishments)/[0-9]+(/attachments/)[0-9]+/(resend$|resend/$)'
    }
  ];

  endpointFailures: CrudTestError[] = [];
  errorRates = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  statusCodes = [
    {
      id: 500,
      name: '500'
    },
    {
      id: 400,
      name: '400'
    },
    {
      id: 409,
      name: '409'
    },
    {
      id: 404,
      name: '404'
    },
    {
      id: 403,
      name: '403'
    },
    {
      id: 401,
      name: '401'
    }
  ];

  static verifySettings(toastrService: ToastrService) {
    const endpointFailures = localStorage.getItem('endpointFailures');
    if (endpointFailures) {
      if (endpointFailures != JSON.stringify(ErrorComponent.defaultEndpointFailures)) {
        toastrService.show('Applied error customizations', 'Error Overrides Found', ErrorComponent._toastrConfig);
      }
    }
  }
  ngOnInit(): void {
    const endpointFailures = localStorage.getItem('endpointFailures');
    if (endpointFailures) {
      this.endpointFailures = JSON.parse(localStorage.getItem('endpointFailures'));
    } else {
      this.endpointFailures = ErrorComponent.defaultEndpointFailures;
      localStorage.setItem('endpointFailures', JSON.stringify(this.endpointFailures));
    }
  }

  getErrorDivClass(odd: boolean) {
    return odd ? 'bg-light' : 'bg-transparent';
  }
  throwJsError() {
    this.buttonClicked = true;
    throw new Error('JS Error');
  }
  throwHttpError(status: number) {
    this.buttonClicked = true;
    const error =
      status == 400
        ? {
            error: {
              developerException: {
                message: 'Developer Exception Message'
              },
              message: 'Test'
            }
          }
        : undefined;
    throw new HttpErrorResponse({
      status,
      error,
      url: 'http://test.com'
    });
  }

  clearError(i) {
    const clear = confirm('Are you sure?');
    if (clear) {
      this.endpointFailures = this.endpointFailures.filter((_, index) => index !== i);
    }
  }
  turnOffError(i) {
    if (this.endpointFailures[i].failureRate == 0) {
      this.endpointFailures[i].failureRate = 10;
    } else {
      this.endpointFailures[i].failureRate = 0;
    }
  }
  addError() {
    this.endpointFailures.push({
      failureRate: 10,
      errorCode: 500,
      canDelete: true
    } as any);
  }
  save() {
    localStorage.setItem('endpointFailures', JSON.stringify(this.endpointFailures));
    if (!this.isHosted) {
      alert('Error settings updated');
    }
  }

  get hasChanges() {
    const localSettings = JSON.stringify(this.endpointFailures);
    const storageSettings = localStorage.getItem('endpointFailures') || JSON.stringify([]);

    return localSettings != storageSettings;
  }
  getOffButtonClass(i) {
    return this.endpointFailures[i].failureRate == 0 ? 'btn-outline' : 'btn-primary';
  }
  reset() {
    this.endpointFailures = ErrorComponent.defaultEndpointFailures;
  }
}
