import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApiDataAdapter } from '../../adapter/adapter';
import { CrudServiceBase } from '../crud-orchestrator/crud-base.service';
import { CrudServiceParams } from '@safarilaw-webapp/shared/common-objects-models';

/**
 * @deprecated - 9989 - After we remove rollback code get rid of this entire class
 */
@Injectable({ providedIn: 'root' })
export class CrudGenericService extends CrudServiceBase<any> {
  private constructor(httpClient: HttpClient) {
    super();
  }
  /**
   * @deprecated - 9989 - After we remove rollback code get rid of this entire class
   */
  create(object: any, endpoint: string, adapter: ApiDataAdapter<any> = null): Observable<any> {
    return this._post(endpoint, object, adapter);
  }
  /**
   * @deprecated - 9989 - After we remove rollback code get rid of this entire class
   */
  retrieve(endpoint: string, payload: any = null, adapter: ApiDataAdapter<any> = null): Observable<any> {
    return this._retrieveJsonResponse(endpoint, payload, adapter);
  }
  /**
   * @deprecated - 9989 - After we remove rollback code get rid of this entire class
   */
  retrieveAll(endpoint: string, params: CrudServiceParams = null, adapter: ApiDataAdapter<any> = null): Observable<{ items: any[]; totalCount: number }> {
    const queryString = this.getQueryStringFromParams(params);
    return this._retrieveAll(endpoint + (queryString.length > 0 ? '?' + queryString : ''), adapter);
  }
  /**
   * @deprecated - 9989 - After we remove rollback code get rid of this entire class
   */
  update(object: any, endpoint: string, adapter: ApiDataAdapter<any> = null): Observable<any> {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return -- this is a generic service, so... "any"
    return this._put(object, endpoint, adapter).pipe(map(() => object));
  }
  /**
   * @deprecated - 9989 - After we remove rollback code get rid of this entire class
   */
  delete(endpoint: string, body: any): Observable<any> {
    return this._delete(endpoint, body);
  }
}
