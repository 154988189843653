export enum NavbarItemId {
  ActionAlerts = 100,
  Unassigned = 101,
  AssignedToMe = 103
}
export enum FileOperationActions {
  CancelTransfer = 0,
  UpdateFileUploadProgress = 1,
  ProcessFileFail = 2,
  ProcessFileSuccess = 3,
  ClearFileInfoFromStore = 4,
  UpdateFileDownloadProgress = 5,
  PrepareForBulkTransfer = 6,
  PreviewFile = 7,
  PreviewFileClosed = 8,
  PreviewFileEdit = 9
}
// Make sure these are strings and not numbers
// This will ensure that they will never collide with app roles from API
export enum UserPermissionType {
  Admin = '1',
  Creator = '2',
  Editor = '3',
  Viewer = '4',
  Uploader = '5',
  Owner = '6',
  // These are "special" values that are not passed as roles in the api,
  // but we can use these in things like PermissionGuardService when defining route restrictions
  // They will be translated by userpermissionsservice
  Self = '9997',
  GlobalMatterAccess = '9998',
  Coordinator = '9999'
}
