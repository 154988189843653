<div  [ngClass]="getDialogClass()">
  <div class="modal-header">
    <h4 class="modal-title pull-left"> {{ getErrorTitle() }}</h4>
  </div>
  <div class="modal-body">
    @if (getMessageHeaderFromErrorObject() !== '') {
      <p class="lead">{{ getMessageHeaderFromErrorObject() }}</p>
    }
    @if (isSafariValidationError()) {
      <div>
        @if(errorObject.details?.length) {
          @if (maxValidationErrors > 1) {
            @for (detail of errorObject.details; track $index) {
              <ul >
                <li> {{ detail.message }} </li>
              </ul>
            }
          } @else {
            <div>
              {{
              errorObject.details[0].message
              }}
              @if (showDeveloperExceptions() && errorObject.details[0].target) {
                <span>({{errorObject.details[0].target}})</span>
              }
            </div>
          }
        } @else {
          <div>
            {{ errorObject.message  }}
          </div>
        }

      </div>
    }
    @if (isConflictError()) {
      <div>
        You cannot save your changes because another user or the Safari system has updated data affecting this page.  Click OK to refresh the page.  You will then be able to re-enter your data and save changes.
        <br><br>
        NOTE: Any data you entered without successfully saving will be lost when the page refreshes.
      </div>
    }
    @if (!isKnownError()) {
      <div>
        {{ errorObject.message ? errorObject.message : 'Unknown Error' }}
        <br>
          {{ url }}
        </div>
      }
      @if (errorObject.developerException && showDeveloperExceptions()) {
        <div>
          <br>
            <a  class="text-muted"   role="button"  (click)="isCollapsed = !isCollapsed" >
              Click here for detailed exception (dev mode only)
            </a>
            <div  [collapse]="isCollapsed">
              <h5 class="my-2"><u ><strong></strong></u></h5>
              <div ><strong>Message:</strong> </div>
              <div> {{ errorObject.developerException.message }} </div>
              <div ><strong>Source:</strong> </div>
              <div> {{ errorObject.developerException.source }} </div>
              <div ><strong>Stack Trace: </strong></div>
              <div class="mb-2 my-2 w-100 overflow-auto" style="height: 200px; ">
                {{ errorObject.developerException.stackTrace }}
              </div>
            </div>
          </div>
        }
        <!-- I'm not even sure that it's possible to get jsStack in this dialog because the dialog is invoked only for validation and conflict
        errors, both of which are API-driven so they won't be any JS Stack with it. But probably won't hurt to leave, just in case... -->
        @if (errorObject.jsStack && showDeveloperExceptions()) {
          <div>
            <h5 class="my-2"><u ><strong>Detailed JS Error</strong></u></h5>
            <div class="mb-2 my-2 w-100 overflow-auto" style="height: 200px; "> {{ errorObject.jsStack }}</div>
          </div>
        }
        <!-- Should we even show unique ID for any of these errors? This dialog is invoked only for known errors like API validation or conflict resolution. -->
        @if (!isConflictError() && !isSafariValidationError()) {
          <div>
            <hr>
              <span >Unique ID: {{ errorObject.uuid }}</span>
            </div>
          }


        </div>
        <div class="modal-footer justify-content-center">
          <button type="button" class="btn btn-primary" (click)="closeModal()">Ok</button>
        </div>
      </div>