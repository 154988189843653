import { SafariObject } from '../../base-object/models/app/safari-object-base';
import { TransferDialogOptions } from '../../redux/file-operation';

export class FileObject extends SafariObject {
  // file is declared as optional because some objects (AttachmentMetadata)
  // may be mixing files with JSON (same endpoint, different logic between PUTs/POSTs)
  file?: File;
  // I don't think this should be part of FileObject. This should be in those actionOptions for the file.
  shouldUseTus?: boolean;
  // The two properties below should be gone after 9990. If we need extra things
  // that we post in formValues while uploading (or even updating) a file we define that model
  // as override of FileObject and just add the properties there. The adapter will convert it.
  additionalInfo?: any;
  formValues?: Map<string, string>;
}
export type FileObjectForTransferDialog<T = FileObject> = T & { transferDialogOptions?: TransferDialogOptions };
export const INVALID_FILE_CHARACTERS = '["<>|:*?\\/]';
export const INVALID_FILE_CHARACTERS_MESSAGE = 'Name cannot contain ' + INVALID_FILE_CHARACTERS.replace('[', '').replace(']', '').split('').join(' ');
