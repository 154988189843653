import { Injectable } from '@angular/core';

import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { CoManageDataAccessObject } from '@safarilaw-webapp/feature/co-manage/data-access';
import { ActionSilenceErrorMode, SafariInjector, SafariObject } from '@safarilaw-webapp/shared/common-objects-models';
import { dataAccessMixin, SelectorState } from '@safarilaw-webapp/shared/redux';
import { merge, Observable } from 'rxjs';
import { AuthClaim, AuthService } from '../auth/auth.service';
@Injectable({
  providedIn: 'root'
})
export class CoUserSsoService extends dataAccessMixin(SafariInjector) {
  CoManageObject: CoManageDataAccessObject;

  constructor(
    private _authService: AuthService,
    protected _actions: Actions,
    protected _store: Store<any>
  ) {
    super();
    this.CoManageObject = this.inject(CoManageDataAccessObject);
  }

  getSsoUser$(): Observable<any> {
    const connectionName = this._authService.getIdClaim(AuthClaim.ConnectionName);
    const userEmail = this._authService.getIdClaim(AuthClaim.Email);

    const id = SafariObject.id(connectionName, userEmail);

    this.loadObject(this.CoManageObject.SsoUser, id, {
      options: {
        silenceErrors: {
          mode: ActionSilenceErrorMode.All
        }
      }
    });

    return merge(this.observeObject$(this.CoManageObject.SsoUser, id, SelectorState.Current), this.observeObject$(this.CoManageObject.SsoUser, id, SelectorState.FailedToLoad));
  }

  hookupSsoUser$(databaseAndUserId: string): Observable<any> {
    // Id will be what we receive back from the getSsoUser call, so something like database-name,joesmith@testco1.aut
    // but we need to call users/{0}/setupAuth/NOID   so we'll extract the object id from the databaseAndUserId
    // and append a NOID to it.
    const id = SafariObject.id(SafariObject.idObjectOnly(databaseAndUserId), SafariObject.NOID);

    return this.createOrUpdateObjectOnce$(this.CoManageObject.UserAuthSetupHelper, { id }, {});
  }
}
