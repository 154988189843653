import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ICustomProperties } from '@microsoft/applicationinsights-web';
import { AppConfigurationService } from '@safarilaw-webapp/shared/environment';

import { HTTP_STATUS_CODE_API_NOTAUTHORIZED, HTTP_STATUS_CODE_API_NOTFOUND, HTTP_STATUS_CODE_WEB_MATTER_FILE_VALIDATION, IReducerErrorLogger } from '@safarilaw-webapp/shared/common-objects-models';
import { AppContext } from '../../../context/models/models';
import { AppContextService } from '../../../context/services/app-context/app-context.service';
import { ApplicationInsightsService } from '../application-insights/application-insights.service';

export enum ElmahSeverity {
  Information = 'Information',
  Warning = 'Warning',
  Error = 'Error'
}
@Injectable({
  providedIn: 'root'
})
export class LoggerService implements IReducerErrorLogger {
  private _elmahLogger: any;
  private _elmahEnabled = false;

  constructor(
    private _appConfig: AppConfigurationService,
    private _appInsights: ApplicationInsightsService,
    private _appContextService: AppContextService
  ) {}

  public initializeElmahLogging() {
    // Setup Elmah logging, if the apikey and logid are valid
    if (this._appConfig.loggers && this._appConfig.loggers.elmah && this._appConfig.loggers.elmah.apiKey && this._appConfig.loggers.elmah.logId) {
      this._elmahEnabled = true;
      // DO NOT remove @ts-ignore comment below
      // ElmahIO doesn't have (at this time) an official import for ElmahIO so newing it up
      // would throw a compile error. We used to have import from 'nodemodules/elmah...' but that seriously
      // breaks any UTs that end up importing anything from ErrorHandler project barrel file (which in turn contains this file)
      // Instead we will just ignore the compile error. This will work fine realtime though, because the way
      // elmah is imported is via script statement inside angular.json, so ElmahIO will be available at runtime
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment  -- comments above
      // @ts-ignore
      this._elmahLogger = new Elmahio({
        apiKey: this._appConfig.loggers.elmah.apiKey,
        logId: this._appConfig.loggers.elmah.logId,
        application: this._appConfig.applicationName
      })
        .on('message', msg => {
          try {
            if (!msg.data) {
              msg.data = [];
            }

            msg.url = msg.url ?? window.location.href;
            msg.hostname = msg.hostname ?? window.location.host;
            msg.version = this._appConfig.sourceVersion ?? 'unknown';

            const appContext = this._appContextService.getAppContext();

            msg.user = appContext.user.authUserId;

            // Elmah hates having arrays in value of data object. It will fail with 400 if that happens.
            // And prior to 3.7.1 it wouldn't even notify of failure so it would be impossible to tell what happened.
            // Note that none of these values below should be arrays or anything
            // else thats not easily convertable to string, but let's stringify just in case
            msg.data.push({ key: 'service-worker', value: JSON.stringify(navigator?.serviceWorker?.controller != null) });
            msg.data.push({ key: 'company-id', value: JSON.stringify(appContext.company.id) });
            msg.data.push({ key: 'user-id', value: JSON.stringify(appContext.user.id) });
            msg.data.push({ key: 'user-authUserId', value: JSON.stringify(appContext.user.authUserId) });
            msg.data.push({ key: 'user-isAdmin', value: JSON.stringify(appContext.user.isAdmin) });
            msg.data.push({ key: 'user-isCoordinator', value: JSON.stringify(appContext.user.isCoordinator) });
            msg.data.push({ key: 'user-hasAccessToAllMatterSubtypes', value: JSON.stringify(appContext.user.hasAccessToAllMatterSubtypes) });
            if (appContext.user.roles != null && Array.isArray(appContext.user.roles)) {
              for (let i = 0; i < appContext.user.roles.length; i++) {
                msg.data.push({ key: `user-role[${i}]`, value: JSON.stringify(appContext.user.roles[i]) });
              }
            }
          } catch (error) {
            this._logElmahException(error);
          }
        })
        .on('error', (status, text) => {
          // This will be raised if elmah throws an exception (and as of 3.7.1 400s will be included as well)
          // It's pretty terse information-wise but at least it will be logged and might help us identify the problem
          // when working with elmah support team
          this._logElmahException(new Error(text));
        });
    }
  }
  private _logElmahException(error) {
    let appContext = null;
    try {
      appContext = this._appContextService.getAppContext();
    } catch {
      // go without it
    }
    // NOTE: We are using appInsights to log elmah error. We don't want to use elmah
    // in case this was some long running error because in that case an error to log
    // would attempt to log to elmah, which then would error out again , attempt to log, and so forth.
    // appInsights logging is a safe way of not getting into that loop
    this._appInsights.logError(appContext, { error });
  }
  LogChunkErrorRecoveryToElmah(detail, count) {
    try {
      if (!this._elmahEnabled) {
        return;
      }

      const elmahMessage = this._elmahLogger.message();
      elmahMessage.title = 'Chunk Error Recovery';
      elmahMessage.type = 'Other';
      elmahMessage.severity = 'Error';
      elmahMessage.detail = detail;
      elmahMessage.data.push({ key: 'chunkRetryCount', value: count });
      this._elmahLogger.log(elmahMessage);
    } catch (err) {
      // eslint-disable-next-line no-console -- We want to keep this console.log
      console.error('Failed to log error page view to elmah due to: ', err);
      this._logElmahException(err);
    }
  }
  LogInfoMessageToElmah(title, message, data: { key: string; value: string }[] = null, severity: ElmahSeverity = ElmahSeverity.Information) {
    try {
      if (!this._elmahEnabled) {
        return;
      }

      const elmahMessage = this._elmahLogger.message();
      elmahMessage.title = title;
      elmahMessage.type = 'Other';
      elmahMessage.severity = severity;
      elmahMessage.detail = message;
      elmahMessage.data = data;
      this._elmahLogger.log(elmahMessage);
    } catch (err) {
      // eslint-disable-next-line no-console -- We want to keep this console.log
      console.error('Failed to log info message to elmah due to: ', err);
      this._logElmahException(err);
    }
  }
  LogStripeDelayToElmah() {
    try {
      if (!this._elmahEnabled) {
        return;
      }
      const elmahMessage = this._elmahLogger.message();

      elmahMessage.title = 'Stripe processing delay';
      elmahMessage.type = 'Other';
      elmahMessage.severity = 'Information';
      elmahMessage.detail = 'Stripe taking longer than expected';
      this._elmahLogger.log(elmahMessage);
    } catch (err) {
      // eslint-disable-next-line no-console -- We want to keep this console.log
      console.error('Failed to log error page view to elmah due to: ', err);
    }
  }

  LogLoginErrorPageViewToElmah(detailObject) {
    try {
      if (!this._elmahEnabled) {
        return;
      }
      const elmahMessage = this._elmahLogger.message();

      elmahMessage.title = 'Login Error Page View';
      elmahMessage.type = 'Other';
      elmahMessage.severity = 'Error';
      elmahMessage.detail = JSON.stringify(detailObject, null, 3);

      this._elmahLogger.log(elmahMessage);
    } catch (err) {
      // eslint-disable-next-line no-console -- We want to keep this console.log
      console.error('Failed to log login error page view to elmah due to: ', err);
      this._logElmahException(err);
    }
  }

  LogErrorPageViewToElmah(detail, uuid) {
    try {
      if (!this._elmahEnabled) {
        return;
      }
      const elmahMessage = this._elmahLogger.message();

      elmahMessage.title = 'Error Page View';
      elmahMessage.type = 'Other';
      elmahMessage.severity = 'Information';
      elmahMessage.detail = detail;

      elmahMessage.data.push({ key: 'uuid', value: uuid });
      this._elmahLogger.log(elmahMessage);
    } catch (err) {
      // eslint-disable-next-line no-console -- We want to keep this console.log
      console.error('Failed to log error page view to elmah due to: ', err);
      this._logElmahException(err);
    }
  }

  LogReducerNullPayload(action) {
    // NOTE: We could easily log entire action and not just the type but that could
    // potentially also log PII (even though payload is null, maybe originalPayload has some data ?).
    // So we'll just log action.type to at least tell us which action had null payload

    try {
      if (!this._elmahEnabled) {
        return;
      }
      const elmahMessage = this._elmahLogger.message();

      elmahMessage.title = 'NULL action payload';
      elmahMessage.type = 'Other';
      elmahMessage.severity = 'Information';
      elmahMessage.detail = action.type;
      this._elmahLogger.log(elmahMessage);
    } catch (err) {
      // eslint-disable-next-line no-console -- We want to keep this console.log
      console.error('Failed to log null action payload to elmah due to: ', err);
      this._logElmahException(err);
    }
  }

  LogError(thrownError: any, pageUrl: string, pageContext = null, elmahErrorType = 'Other') {
    try {
      const appContext = this._appContextService.getAppContext();
      // Keep this here for now. It doesn't hurt to log raw
      // error to the console.
      let stringifiedError = '';
      try {
        // eslint-disable-next-line no-console -- We want to keep this console.log
        console.error('Thrown error', thrownError);
        stringifiedError = JSON.stringify(thrownError, null, 2);
      } catch {
        stringifiedError = 'Can not stringify';
        // Do nothing - but don't break with 'JSON.stringify can't parse...'
      }
      if (this.shouldBeLogged(thrownError, pageUrl)) {
        // Don't log web validation to elmah, only appinsights
        if (this._elmahEnabled) {
          this._logErrorToElmah(thrownError, stringifiedError, pageUrl, appContext, pageContext, elmahErrorType);
        }
        this._appInsights.logError(appContext, { id: thrownError.uuid, error: thrownError });
      }
    } catch (err) {
      // eslint-disable-next-line no-console -- We want to keep this console.log
      console.error('Failed to log log error due to: ', err);
      // There's not much we can do here, but don't want to blow up during logging for any reason
    }
  }

  trackEvent(name: string, customProperties?: ICustomProperties) {
    this._appInsights.trackEvent(name, customProperties);
  }

  private shouldBeLogged(thrownError: any, pageUrl: string): boolean {
    /* Basic exclusions */
    if (thrownError.status == HTTP_STATUS_CODE_API_NOTAUTHORIZED) {
      return false;
    }
    // I m not 100% sure here if we should simply not log "any" web validation (status > HTTP_STATUS_CODE_WEB_VALIDATION_BASE)
    // or just this one. I'm going with the latter for now because no other web-validation errors were being excluded prior to this
    // and also because 10320 should obsolete this whole method (or most of it)
    if (thrownError.status == HTTP_STATUS_CODE_WEB_MATTER_FILE_VALIDATION) {
      return false;
    }

    /* Error matching exclusions */
    if (thrownError instanceof HttpErrorResponse && thrownError.url?.includes('/users/') && thrownError.url?.includes('/signature') && thrownError.status == HTTP_STATUS_CODE_API_NOTFOUND) {
      return false;
    }

    return true;
  }

  private _logErrorToElmah(thrownError: any, stringifiedError: string, url: string, appContext: AppContext, pageContext: any, type: string) {
    try {
      // Elmah's error function requires plain JS UI error or it will blow up
      // (we wont have a plain JS error if the error came from an observable or some network call)
      let elmahMessage;
      if (thrownError instanceof Error) {
        elmahMessage = this._elmahLogger.message(thrownError);
      } else {
        elmahMessage = this._elmahLogger.message();
        elmahMessage.title = this.getErrorTitle(thrownError);
        elmahMessage.severity = this.getErrorSeverity(thrownError);
        elmahMessage.type = type;
        elmahMessage.detail = stringifiedError;
        elmahMessage.statusCode = thrownError.status;
      }

      elmahMessage.url = thrownError.url ?? url;
      elmahMessage.correlationId = thrownError.uuid ?? 'unknown';

      // Note all the JSON.stringifies below. Turns out that elmah has a bug where if one of the values is
      // array it will fail to log. Really we only should have to do this on headers array further below
      // but just in case we'll wrap everything in it
      elmahMessage.data.push({ key: 'uuid', value: JSON.stringify(thrownError.uuid) });

      elmahMessage.data.push({ key: 'app-url', value: JSON.stringify(url) });
      elmahMessage.data.push({ key: 'silent', value: thrownError.silent != null ? JSON.stringify(thrownError.silent) : 'false' });
      elmahMessage.data.push({ key: 'pageContext', value: JSON.stringify(pageContext) });

      if (thrownError instanceof HttpErrorResponse && typeof thrownError?.headers?.keys == 'function') {
        for (const header of thrownError.headers.keys()) {
          elmahMessage.data.push({ key: 'RESPONSE HEADER: ' + header, value: JSON.stringify(thrownError.headers.getAll(header)) });
        }
      }

      this._elmahLogger.log(elmahMessage);
    } catch (err) {
      // eslint-disable-next-line no-console -- We want to keep this console.log
      console.error('Failed to log error due to: ', err);
      this._logElmahException(err);
    }
  }

  private getErrorTitle(thrownError: any) {
    if (thrownError instanceof Error) {
      return thrownError.message ?? thrownError.name;
    }
    if (thrownError.status == 400) {
      return 'Validation Error';
    } else if (thrownError.status == 404) {
      return '404 - Not Found';
    } else if (thrownError.status == 403) {
      return '403 - Not Authorized';
    } else if (thrownError.status == 409) {
      return '409 - Conflict';
    } else if (thrownError.status == 500) {
      return '500 - Internal Server Error';
    } else if (thrownError.status == 502) {
      return '502 - Gateway Timeout';
    }
    return 'Other Error';
  }

  private getErrorSeverity(thrownError: any) {
    if (thrownError.status == 400 || thrownError.status == 404 || thrownError.status == 409) {
      return 'Warning';
    }
    return 'Error';
  }
}
