<div class="wrapper">
   <main class="s_bg-maint" [ngClass]="customThemeCss">
      <div class="container-fluid">
         <div class="justify-content-center">
            <div class="col-md-12 text-center mt-5 s_banner" [ngClass]="customThemeBannerCss">
               <span class="display-4 d-block">{{title}}</span>
               <div class="mb-4 lead">{{details}}</div><a
                  class="btn btn-link" href="/">Return to Login Page</a>
            </div>
         </div>
      </div>
   </main>
</div>