import { SafariObjectId } from '../base-object/models/app/safari-object-base';
import { PageUiAction } from './page-ui';

export enum FileOperationType {
  Add,
  Remove,
  Update,
  Download,
  RemoveMultiple,
  Move
}

// Typescript uses structural typing, meaning that we can't use things
// like empty marker interfaces to catch type errors in compiler. Instead
// the below solution can be used to create nominal types similar to marker interfaces
// TO USE - add the following the the app's file uploader implementation:
// interface IXYZFileOperationMetadataAdditionalInfo {
//   someProp: SomeType;
// }
// export type XYZFileOperationMetadataAdditionalInfo = FileOperationMetadataAdditionalInfo & IXyzFileOperationMetadataAdditionalInfo;
const brand = Symbol();
export interface FileOperationMetadataAdditionalInfo {
  [brand]: never;
}

export class FileOperationMetadata {
  // parent's etag. May or may not be needed, depending on the use case
  etag?: string;
  // anything that makes sense to a specific implementation of file uploader
  additionalInfo?: FileOperationMetadataAdditionalInfo | any;
  // additional form values that will be sent with the file
  formValues?: Map<string, string> = null;
}
/**
 * TODO: #10266
 */
// Base class for all file operations
export class FileOperationBase {
  id?: string; // if present means replace
  objectTypeName?: string;
  isSubfolder?: boolean;
  file?: File; // file contents
  folder?: string;
  subfolder?: string;
  correlationId?: string;
  secondsUntilTransferDialogShown?: number; // How many seconds should elapse before we popup the dialog. Usually 0. -1 means never
  actionId?: string; // Must be present - if not assigned by the caller it will be assigned by the framework
  fileName?: string; // "Raw" filename
  displayFilename?: string; // displayFilename - if not assigned by the caller it will be assigned by the service (default = raw filename)
  parentId?: SafariObjectId; // Parent object (company Id, subpoena Id, etc)
  metadata?: FileOperationMetadata; // Whatever makes sense to a particular service
  originalContent?: any;
}
// Base class for upload and remove file operations
export class FileOperationRequest extends FileOperationBase {}
export class BulkFileOperationRequest {
  files?: FileOperationInfo[];
}
export class ClearFileInfoPayload {
  id: string;
  fileTransferDialogId: string;
  hadErrors: boolean;
  wasCancelled: boolean;
}
// File upload class
export class FileOperationUploadRequest extends FileOperationBase {}
// File remove class
export class FileOperationRemoveRequest extends FileOperationBase {}
export class FileOperationMoveRequest extends FileOperationBase {}
export class FileOperationMoveMultipleFilesRequest extends FileOperationBase {
  folder: string;
  subfolder?: string;
  fileRequests: FileOperationMoveRequest[]; // ID of the file object
}
export class FileOperationRemoveMultipleFilesRequest extends FileOperationBase {
  useQueryString?: boolean;
  fileRequests: FileOperationRemoveRequest[]; // ID of the file object
}
export class FileOperationDownloadRequest extends FileOperationBase {
  isPreview?: boolean;
  headers?: { [key: string]: string };
}

// Info class. Used by the framework to display information about the file
export class FileOperationInfo extends FileOperationBase {
  apiResponse?: any;
  isPreview?: boolean;
  downloadLink?: string; // download link returned from the API
  percentComplete?: number; // progress
  isError?: boolean; // error flag
  message?: string; // message to display in the dialog
  fileOperationType?: FileOperationType; // remove or add
  totalSize?: number;
  totalProcessed?: number;
  isMetadataUpdate?: boolean;
}

export class FilePreviewCloseRequest extends PageUiAction {
  viewerId?: SafariObjectId;
}
/**
 * FileDownloadRequest
 * Used by any component in the framework to request file download.
 */
export class FileDownloadRequest extends PageUiAction {
  /**
   * Where to download
   */
  target?: string;
  /**
   * object to download.
   * It can be either a File from the browser or an object that the framework will use to generate a templink
   * and download from there.
   * In the latter case the object must have an id and an attachmentType as string.
   */
  objectToDownload:
    | {
        id: SafariObjectId /**
         * this will be reduxObjectType toString() so it can be seralized.
         * For example this.RmsObject.AttachmentLink.toString()
         */;
        attachmentType: string;
        attachmentIds?: SafariObjectId[];
      }
    | File;
  /**
   * Desired filename
   */
  fileName?: string;
  /**
   * DOM id of the HTML element we'd like to disable when templink generation starts
   * and re-enable when download begins
   */
  callerId?: SafariObjectId | SafariObjectId[];
}
export const BROWSERVIEWER = '424b9c0a-64e1-463f-8e01-227defc82582_BROWSERVIEWER';
/**
 * FilePreviewRequest
 * Used by any component in the framework to request file preview.
 */
export class FilePreviewRequest extends PageUiAction {
  /**
   * Id of the viewer (if the page has multiple viewers)
   */
  viewerId?: SafariObjectId;
  /**
   * DOM id of the HTML element we'd like to disable when templink generation starts
   * and re-enable when download begins
   */
  callerId?: SafariObjectId | SafariObjectId[];

  /**
   * object to preview.
   * It can be either File, string, an object used to generate a templink or NULL. NULL signifies clipboard paste.
   * String is a simple direct link (don't think we have cases of that in the app though)
   * An object to generate a templink must have an id and an attachmentType as string.
   */
  objectToPreview:
    | {
        id: SafariObjectId /**
         * this will be reduxObjectType toString() so it can be seralized.
         * For example this.RmsObject.AttachmentLink.toString()
         * NOTE: We can't  use this.RmsObject.XYZ in child components as we don't allow this to be passed down.
         * This is why every data access layer exports list of AttachnemtNames as strings that child components can use
         */;
        attachmentType: string;
        attachmentIds?: SafariObjectId[];
      }
    | File
    | string
    | null;
  linkOriginInfo?: {
    id: SafariObjectId;
    attachmentType: string;
  };
  additionalInfo?: any;
  fileName?: string;

  canEditFileName?: boolean;
  hideDownloadButton?: boolean;
  hideCloseButton?: boolean;
  hideHeader?: boolean;
  hideFilename?: boolean;

  fileType?: string;
}
export class FilePreviewResponse {
  fileName?: string;
  additionalInfo: any;
  __responseType?: FilePreviewResponseType; // autopopulated by reducer
}
export class RemoveFileInfo extends FileOperationInfo {}

export enum FilePreviewResponseType {
  Closed,
  Edit
}
export class TransferDialogOptions {
  message?: string;
  displayName?: string;
  additionalInfo?: any;
  secondsUntilTransferDialogShown?: number;
  // originalContent is for failedObjectsService and can be anything that makes sense for the caller
  // It will be stored in failedObjectService so when the page reloads the caller can figure out what to do with it
  originalContent?: any;
}
