/** WEB VALIDATION ERRORS */
export const HTTP_STATUS_CODE_WEB_VALIDATION_BASE = 90000;

// This is a mixture of generic web validation errors (tus) and lpms-specific web validation errors
// THe latter are probably not ideal to keep in "common-objects-models" library as it is project specific
// but leaving alone for now because we are checking for some of these in LoggerService and dont want to
// start splitting up projects for the sake of 2 constants.
export const HTTP_STATUS_CODE_WEB_MATTER_FILE_VALIDATION = HTTP_STATUS_CODE_WEB_VALIDATION_BASE + 9999;
export const HTTP_STATUS_CODE_WEB_TEMPLATE_VALIDATION = HTTP_STATUS_CODE_WEB_VALIDATION_BASE + 9998;
export const HTTP_STATUS_CODE_WEB_TUS_CLIENT_ERROR = HTTP_STATUS_CODE_WEB_VALIDATION_BASE + +9997;

/** END WEB VALIDATION ERRORS*/
export const HTTP_STATUS_CODE_API_VALIDATION = 400;
export const HTTP_STATUS_CODE_API_CONFLICT = 409;
export const HTTP_STATUS_CODE_API_NOTFOUND = 404;
export const HTTP_STATUS_CODE_API_NOTAUTHORIZED = 401;
export const HTTP_STATUS_CODE_API_FORBIDDEN = 403;
export const HTTP_STATUS_CODE_API_TOOMANY = 429;
export const HTTP_STATUS_CODE_API_TIMEOUT = 408;
export const HTTP_STATUS_CODE_API_NOTMODIFIED = 304;
export const HTTP_STATUS_CODE_API_SERVERERROR = 500;

export const HTTP_STATUS_CODE_GATEWAY_TIMEOUT = 504;
export const HTTP_STATUS_CODE_UNKNOWN_ERROR = 0;
