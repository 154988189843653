import { Injectable } from '@angular/core';
import { ApiCallContext, SafariObject } from '@safarilaw-webapp/shared/common-objects-models';
import { SafariRequired } from '@safarilaw-webapp/shared/crud';
import { IntakePortalListModel } from '../../_rms-api-models.generated';
import { RmsDataAdapter } from '../../shared/rms-base-adapter';
import { CreateRecentIntakePortal } from '../models/api/create-recent-intake-portal';
import { MyIntakePortal } from '../models/app/my-intake-portal';

@Injectable({
  providedIn: 'root'
})
export class MyIntakePortalAdapter extends RmsDataAdapter<MyIntakePortal> {
  toCreateModel(model: SafariObject, context: ApiCallContext = null): SafariRequired<CreateRecentIntakePortal> {
    return {
      intakePortalId: SafariObject.id(model.id)
    };
  }
  fromListModel(model: IntakePortalListModel, context: ApiCallContext = null): SafariRequired<MyIntakePortal> {
    return {
      id: this.helpers.fromApiId(model.id),
      companyId: this.helpers.fromApiId(model.companyId),
      companyName: model.companyName,
      isActive: model.isActive,
      title: model.title,
      shortName: model.shortName,
      urlPath: model.urlPath,
      authorizedDomains: model.authorizedDomains,
      isAgencyPortal: model.isAgencyPortal
    };
  }
}
