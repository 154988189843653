import { NavbarGroup, navbarGroupIdDashboard, NavbarHeader, NavbarItem } from '@safarilaw-webapp/shared/app-bootstrap';
import { environmentImports } from './navbar-item-collection.env.imports';

export const NavbarItemCollection = [
  new NavbarGroup(navbarGroupIdDashboard, '', []),

  /*********************/
  /* General Serveport */
  /*********************/
  new NavbarHeader({
    id: 100,
    groupId: navbarGroupIdDashboard,
    title: 'Home',
    subtitle: 'ServePort Overview',
    elementId: 's_mnu-link-library',
    link: '/home',
    permissionFunction: ['!isCustomPortal']
  }),
  new NavbarHeader({
    id: 200,
    groupId: navbarGroupIdDashboard,
    title: 'Serve',
    subtitle: 'Submit New Service',
    elementId: 's_mnu-link-intake',
    link: '/serve',
    permissionFunction: ['!isCustomPortal']
  }),
  new NavbarHeader({
    id: 300,
    groupId: navbarGroupIdDashboard,
    title: 'Response',
    subtitle: 'Download Documents',
    elementId: 's_mnu-link-cabinet',
    link: '/portal',
    permissionFunction: ['!isCustomPortal']
  }),
  // new NavbarHeader({ id: 400, groupId: navbarGroupIdDashboard, title: 'Legal', elementId: 's_mnu-link-globe' }),
  new NavbarItem(401, 400, 'Privacy Policy', '/legal#privacy-policy'),
  new NavbarItem(402, 400, 'Terms of Use', '/legal#terms-of-use'),

  /********************************************/
  /* Specialty portals (ClientThemes) */
  /********************************************/
  new NavbarHeader({ id: 100, groupId: navbarGroupIdDashboard, title: 'Home', elementId: 's_mnu-link-library', link: '/home', permissionFunction: ['isCustomClientPortal'] }),

  /********************************************/
  /* Specialty portals (Agency) */
  /********************************************/
  new NavbarHeader({
    id: 200,
    groupId: navbarGroupIdDashboard,
    title: 'Serve',
    subtitle: 'Submit New Service',
    elementId: 's_mnu-link-intake',
    link: '/serve', // This is intentional; home and serve components handle the custom redirects to custom intake portals
    permissionFunction: ['isAgencyPortal']
  }),

  /*********************/
  /*    Other stuff    */
  /*********************/
  ...environmentImports
];
