import { Injectable } from '@angular/core';
import { ApiCallContext } from '@safarilaw-webapp/shared/common-objects-models';
import { SafariRequired } from '@safarilaw-webapp/shared/crud';
import { CreateIntakePortalModel, IntakePortalListModel, IntakePortalModel, UpdateIntakePortalModel } from '../../_rms-api-models.generated';
import { RmsDataAdapter } from '../../shared/rms-base-adapter';
import { IntakePortal } from '../models/app/intake-portal';

@Injectable({
  providedIn: 'root'
})
export class IntakePortalAdapter extends RmsDataAdapter<IntakePortal> {
  toCreateModel(model: IntakePortal, context: ApiCallContext = null): SafariRequired<CreateIntakePortalModel> {
    return {
      isAgencyPortal: model.isAgencyPortal,
      companyId: this.helpers.toApiId(model.companyId),
      companyName: model.companyName,
      description: model.description,
      instructions: model.instructions,
      isActive: model.isActive,
      title: model.title,
      urlPath: model.urlPath,
      shortName: model.shortName,
      authorizedDomains: model.authorizedDomains,
      sensitiveFields: model.sensitiveFields,
      customDomain: undefined,
      excludeFromRecentPortals: undefined
    };
  }
  toUpdateModel(model: IntakePortal, context: ApiCallContext = null): SafariRequired<UpdateIntakePortalModel> {
    return {
      description: model.description,
      instructions: model.instructions,
      isActive: model.isActive,
      title: model.title,
      companyName: model.companyName,
      urlPath: model.urlPath,
      shortName: model.shortName,
      authorizedDomains: model.authorizedDomains,
      sensitiveFields: model.sensitiveFields,
      customDomain: undefined,
      excludeFromRecentPortals: undefined
    };
  }

  fromGetModel(model: IntakePortalModel, context?: ApiCallContext<any>): SafariRequired<IntakePortal> {
    return {
      id: this.helpers.fromApiId(model.id),
      isAgencyPortal: model.isAgencyPortal,
      companyId: this.helpers.fromApiId(model.companyId),
      companyName: model.companyName,
      description: model.description,
      instructions: model.instructions,
      isActive: model.isActive,
      title: model.title,
      urlPath: model.urlPath,
      shortName: model.shortName,
      authorizedDomains: model.authorizedDomains,
      forms: null,
      sensitiveFields: model.sensitiveFields
    };
  }
  fromListModel(model: IntakePortalListModel, context: ApiCallContext = null): SafariRequired<IntakePortal> {
    return {
      id: this.helpers.fromApiId(model.id),
      isAgencyPortal: model.isAgencyPortal,
      companyId: this.helpers.fromApiId(model.companyId),
      companyName: model.companyName,
      description: undefined,
      instructions: undefined,
      isActive: model.isActive,
      title: model.title,
      urlPath: model.urlPath,
      shortName: model.shortName,
      authorizedDomains: model.authorizedDomains,
      forms: model.forms.map(x => ({
        id: this.helpers.fromApiId(x.id),
        matterSubtypeDisplayName: x.matterSubtypeDisplayName,
        matterSubtypeId: this.helpers.fromApiId(x.matterSubtypeId)
      })),
      sensitiveFields: undefined
    };
  }
}
