import { Injectable } from '@angular/core';
import { SafariRequired } from '@safarilaw-webapp/shared/crud';
import { cloneDeep } from 'lodash-es';

import { ApiCallContext } from '@safarilaw-webapp/shared/common-objects-models';
import { CreateSubmissionModel, SubmissionModel } from '../../_rms-api-models.generated';
import { RmsDataAdapter } from '../../shared/rms-base-adapter';
import { Submission } from '../models/app/submission';
import { SubmissionAdapterContext } from './submission-context';

@Injectable({
  providedIn: 'root'
})
export class SubmissionAdapter extends RmsDataAdapter<Submission> {
  fromGetModel(model: SubmissionModel, context?: ApiCallContext<any>): SafariRequired<Submission> {
    return {
      acknowledgmentOfReceipt: model.acknowledgmentOfReceipt
        ? {
            id: this.helpers.fromApiId(model.acknowledgmentOfReceipt.id),
            contentType: model.acknowledgmentOfReceipt.contentType,
            sizeInBytes: model.acknowledgmentOfReceipt.sizeInBytes,
            name: model.acknowledgmentOfReceipt.name
          }
        : null,

      attachments:
        model.attachments == null
          ? null
          : model.attachments.map(x => ({
              id: this.helpers.fromApiId(x.id),
              contentType: x.contentType,
              isSupporting: x.isSupporting,
              malwareScanStatus: x.malwareScanStatus,
              name: x.name,
              sizeInBytes: x.sizeInBytes
            })),
      children: model.children,
      companyId: this.helpers.fromApiId(model.companyId),
      consents: model.consents,
      dateOfSubmission: this.helpers.fromApiDate(model.dateOfSubmission),
      id: this.helpers.fromApiId(model.id),
      intakeFormId: undefined,
      intakePortalId: this.helpers.fromApiId(model.intakePortalId),
      matterId: this.helpers.fromApiId(model.matterId),
      matterSubtypeDisplayName: model.matterSubtypeDisplayName,
      matterSubtypeId: this.helpers.fromApiId(model.matterSubtypeId),
      matterType: model.matterType,
      safariId: model.safariId,
      status: model.status,
      submitterName: model.submitterName,
      temporaryFileIds: undefined,
      values: model.values,
      companyEntityId: this.helpers.fromApiId(model.companyEntityId),
      companyEntityName: model.companyEntityName,
      companyName: model.companyName,
      registeredAgencyId: this.helpers.fromApiId(model.registeredAgencyId)
    };
  }
  toCreateModel(model: Submission, constext: SubmissionAdapterContext): SafariRequired<CreateSubmissionModel> {
    const dateFieldNames: string[] = constext.dateFieldNames ?? ([] as string[]);
    const apiModel = {
      values: cloneDeep(model.values),
      submitterName: model.submitterName,
      consents: model.consents,
      children: cloneDeep(model.children),
      intakeFormId: this.helpers.toApiId(model.intakeFormId),
      intakePortalId: this.helpers.toApiId(model.intakePortalId),
      temporaryFileIds: model.temporaryFileIds
    };

    for (const value of apiModel.values) {
      if (dateFieldNames.includes(value.field.toString())) {
        value.value = this.helpers.toApiDate(value.value);
      }
    }

    for (const child of apiModel.children) {
      for (const value of child.values) {
        if (dateFieldNames.includes(value.field.toString())) {
          value.value = this.helpers.toApiDate(value.value);
        }
      }
    }

    return apiModel;
  }
}
